// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.grid-list .back.active,
.grid-list .imgs:not(.active) {
    display: none;

}`, "",{"version":3,"sources":["webpack://./src/Portfolio.css"],"names":[],"mappings":"AAAA;;IAEI,aAAa;;AAEjB","sourcesContent":[".grid-list .back.active,\n.grid-list .imgs:not(.active) {\n    display: none;\n\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
